//@ts-nocheck

import { callWithNuxt, type UseFetchOptions } from "nuxt/app";
import { storeToRefs } from "pinia";
import { useUserStore } from "~~/stores/user";

export const getFromApi = async <T>(
	url: string | (() => string),
	options: UseFetchOptions<T> = {}
) => {
	// console.log(
	// 	`%cFETCH:%c[${method}]%c[STARTING]%c ${path}`,
	// 	"background-color: #f1c40f;color: white;padding: 2px;",
	// 	"background-color: #3498db;color: white;padding: 2px;",
	// 	"background-color: #2ecc71;color: white;padding: 2px;",
	// 	"background-color; transparent; color: initial;padding: 2px;"
	// );

	// const { data, error, pending, refresh } = await useFetch(encodeURI(path), {
	// 	baseURL: `${
	// 		path === "/auth" ||
	// 		path.includes("/stripe/") ||
	// 		path.includes("/mail/") ||
	// 		path.includes("/forgot_password") ||
	// 		path.includes("/send_verification_email") ||
	// 		path.includes("/suspend_account") ||
	// 		path.includes("/config/")
	// 			? config.public.apiUrl.replace("/v2", "/")
	// 			: config.public.apiUrl
	// 	}`,
	// 	method: method,
	// 	body: body,
	// 	headers: headers,
	// 	server: server
	// });

	// watch(error, (err) => {
	// 	console.error(error.value);
	// 	if (error.value !== null) {
	// 		console.error("[fetch response error]", error.value);
	// 		if (error.value.statusCode == 401 && currentRoute !== "/login") {
	// 			callWithNuxt(nuxtApp, () => {
	// 				return navigateTo({
	// 					path: "/logout",
	// 					query: {
	// 						toLogin: true,
	// 						path: currentRoute,
	// 					},
	// 				});
	// 			});
	// 		} else {
	// 			return cb(response);
	// 		}
	// 	}
	// });

	// console.groupCollapsed(
	// 	`%cFETCH:%c[${method}]%c[END]%c ${path}`,
	// 	"background-color: #f1c40f;color: white;padding: 2px;",
	// 	"background-color: #3498db;color: white;padding: 2px;",
	// 	"background-color: #e67e22;color: white;padding: 2px;",
	// 	"background-color; transparent; color: initial;padding: 2px;"
	// );
	// console.log(data.value)
	// console.warn(error.value)
	// console.groupEnd()

	return useFetch(url, {
		...options,
		$fetch: useNuxtApp().$api
	})
};
