import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/home/runner/work/Macabram/Macabram/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/home/runner/work/Macabram/Macabram/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/runner/work/Macabram/Macabram/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/Macabram/Macabram/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/home/runner/work/Macabram/Macabram/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/home/runner/work/Macabram/Macabram/.nuxt/sentry-client-config.mjs";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/home/runner/work/Macabram/Macabram/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_h_pA3yusmDTJ8f7Mvx2nEWTVV4itdwLzF7i0rRgd_FI from "/home/runner/work/Macabram/Macabram/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import apex_chart_client_WMBl3e_avyCzec2UxIE0ktNBvvLOLQxoLO4JGSthdV4 from "/home/runner/work/Macabram/Macabram/plugins/apex-chart.client.ts";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/home/runner/work/Macabram/Macabram/plugins/api.ts";
import auth_check_8snCUuI0eoSBa7nGEmcNSn8rCv5mfBABZY_Rn5563os from "/home/runner/work/Macabram/Macabram/plugins/auth_check.ts";
import emitter_oQfKxOLDcRrUp73cCkniDW_imBLBpxFewBINYTT07zk from "/home/runner/work/Macabram/Macabram/plugins/emitter.ts";
import vue_good_table_eL6kO0JPPWdWT_kxZhOLLr294ROJHOGgtOWTgMTdhMA from "/home/runner/work/Macabram/Macabram/plugins/vue-good-table.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  plugin_h_pA3yusmDTJ8f7Mvx2nEWTVV4itdwLzF7i0rRgd_FI,
  apex_chart_client_WMBl3e_avyCzec2UxIE0ktNBvvLOLQxoLO4JGSthdV4,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  auth_check_8snCUuI0eoSBa7nGEmcNSn8rCv5mfBABZY_Rn5563os,
  emitter_oQfKxOLDcRrUp73cCkniDW_imBLBpxFewBINYTT07zk,
  vue_good_table_eL6kO0JPPWdWT_kxZhOLLr294ROJHOGgtOWTgMTdhMA
]