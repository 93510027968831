export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();
	const userStore = useUserStore();
	const { token } = storeToRefs(userStore);

	const $api = $fetch.create({
		baseURL: config.public.apiUrl,
		onRequest({ request, options, error }) {
			if (userStore.isLoggedIn) {
				options.headers.set("Authorization", `Bearer ${token.value}`);
			}
			if (options.method == "PATCH") {
				options.headers.set(
					"content-type",
					"application/merge-patch+json"
				);
			} else {
				options.headers.set("Accept", "application/ld+json");
			}
		},
		onResponseError({ response }) {
			if (response.status == 401) {
				return nuxtApp.runWithContext(() => navigateTo("/login"));
			}
		},
	});

	return {
		provide: {
			api: $api,
		},
	};
});
