
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as test2WeDqjFKePMXPhpMHsDZlvrAszVyaMWWEsR0ZL8L7GAMeta } from "/home/runner/work/Macabram/Macabram/pages/test.vue?macro=true";
import { default as indexofQ_nIrkMe0LcACbsiL_VFpj7iaphOKjPcf06xTsCvYMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/index.vue?macro=true";
import { default as usersWAxQjeDbetWe8iGPJA8V1c9xdITAaagONAUCf8Y3tZIMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/users.vue?macro=true";
import { default as orderswmDK15nz5KOGPQgRNmy9h0MOqu6kKFrRUwPFDQiRGmMMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/orders.vue?macro=true";
import { default as productsb0DYw0YT5ABxSlNicHTJk2FxzuquhrhT5LQVVy3_7qcMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/products.vue?macro=true";
import { default as categoriesW9hBrzFqc_eduGNNheGcz2qkt9q6Sy1qdVfEvQrFX9sMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/categories.vue?macro=true";
import { default as _91id_937iXDBf8njUc_45gNVBH5bkASrVPsyL7lpLJuqyr0g2IRcMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/order/[id].vue?macro=true";
import { default as variationsxn_45z483cmBakA7q_45tKByBjyVxXCiPbHcJ0DXQ7EQSXYMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/variations.vue?macro=true";
import { default as settings_46clientb0kUAOKiHOXM1_RPwiuMOFZS_45KAR_b0e8ZtMc1zrI88Meta } from "/home/runner/work/Macabram/Macabram/pages/admin/settings.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/runner/work/Macabram/Macabram/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as indexi5k5WN_45p_45NV83Cgk3fT8_45jYJVS8_ii3n6DWdA5EM3wgMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/order/manual/index.vue?macro=true";
import { default as complete05Qn7j4K8xjVkjQi3t5IyBb8mBfuXrW2Bt85cj3NgwcMeta } from "/home/runner/work/Macabram/Macabram/pages/admin/order/manual/complete.vue?macro=true";
import { default as adminitrJ_45U4dacCqqS6uygq3kM8jXnNmRcaS8OFnBJZrg2QMeta } from "/home/runner/work/Macabram/Macabram/pages/admin.vue?macro=true";
import { default as indexHZZN7L8QDIX8Mw9IEzVjtDwF_byjYICqsHphCSt_mkoMeta } from "/home/runner/work/Macabram/Macabram/pages/index.vue?macro=true";
import { default as legaliyoNMaSFuZJMJc9B6XOFFMA_45Sv939mBRbD7ZnbFOjZQMeta } from "/home/runner/work/Macabram/Macabram/pages/legal.vue?macro=true";
import { default as logini4Sbn4uaC89zjyPXmTnt66610cDvky_45YhQCqdAS4vDsMeta } from "/home/runner/work/Macabram/Macabram/pages/login.vue?macro=true";
import { default as logoutpMeaAKYrpCabF6LyVdGjZ0PIVZTJhLTWDsap2WzvCI8Meta } from "/home/runner/work/Macabram/Macabram/pages/logout.vue?macro=true";
import { default as register2o_c3AE9iAE1q64zRn4FD16v87lGymf3ceM5kFA4fMEMeta } from "/home/runner/work/Macabram/Macabram/pages/register.vue?macro=true";
import { default as homeiVcoqbryHRu0ymtwx7kTihWlHjiL5cnePWUFdpvBPWsMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard/home.vue?macro=true";
import { default as myordersLyMroVOboZziNNKX4bw_4592I2VcIK5gWAfqjuhYvtmDQMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard/myorders.vue?macro=true";
import { default as myaccountyCn4_45A8VC2rY_7lypN7wJ6CtNMOMepPBDNoCNgZ744kMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard/myaccount.vue?macro=true";
import { default as _91id_93p_LlDIWiSUeAZiNuusqBLG9_45ZLhj1kDIOIvxOCeGi3MMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard/order/[id].vue?macro=true";
import { default as dashboard95wsXYzLww8KCVqqU0d6cOwSnhw7xpyHdhO5JQn93UYMeta } from "/home/runner/work/Macabram/Macabram/pages/dashboard.vue?macro=true";
import { default as indexSpKrcTu1T6Qh_cBxqBb0V2m9dlA7kDyvLxKig3v1h5EMeta } from "/home/runner/work/Macabram/Macabram/pages/shop/index.vue?macro=true";
import { default as maintenance0lqdhwvS3xmgF_459wr0_45PyhEZ1fR2UUS6ZfflkTvzc1YMeta } from "/home/runner/work/Macabram/Macabram/pages/maintenance.vue?macro=true";
import { default as verify_emailgwHpR6uv84irMtyPj2BzcJVS_pyix8DKVOIOdC4CcmAMeta } from "/home/runner/work/Macabram/Macabram/pages/verify_email.vue?macro=true";
import { default as account_deletedZEX4e_b_45o4Nkw0picNxVMVBMlKwbj5kyxrH5ASH6yuEMeta } from "/home/runner/work/Macabram/Macabram/pages/account_deleted.vue?macro=true";
import { default as indexaTZcoucQaeRyEhWMeacIV1_KkA_HZWSxDYEz1TUpjK8Meta } from "/home/runner/work/Macabram/Macabram/pages/shop/order/index.vue?macro=true";
import { default as successCNKrGRKsJOmfr67PA4dDrOvqrSG51ICBCCn_Xpzbr2IMeta } from "/home/runner/work/Macabram/Macabram/pages/shop/order/success.vue?macro=true";
import { default as canceledKY4nQPlBCZ8RRMVFTm2pACl5iKSCmL6w2kP3imiaoToMeta } from "/home/runner/work/Macabram/Macabram/pages/shop/order/canceled.vue?macro=true";
import { default as indexhHR8S2Q_453Q1cC1_krlPcq3yHWICScum3vzB6IqkDHfsMeta } from "/home/runner/work/Macabram/Macabram/pages/forgot-password/index.vue?macro=true";
import { default as _91token_93UbWJd6rTTwLfcf0mJx_wzjuvyKJx6UaevXCjQ_tFy9QMeta } from "/home/runner/work/Macabram/Macabram/pages/forgot-password/[token].vue?macro=true";
import { default as _91id_93_45_91name_93py7b8f5wzhoPs4WF2oN3dSvMB4XBpkQd9m55qV3jZX0Meta } from "/home/runner/work/Macabram/Macabram/pages/shop/product/[id]-[name].vue?macro=true";
export default [
  {
    name: "test",
    path: "/test",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/test.vue")
  },
  {
    name: adminitrJ_45U4dacCqqS6uygq3kM8jXnNmRcaS8OFnBJZrg2QMeta?.name,
    path: "/admin",
    meta: adminitrJ_45U4dacCqqS6uygq3kM8jXnNmRcaS8OFnBJZrg2QMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin.vue"),
    children: [
  {
    name: "admin",
    path: "",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/index.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/users.vue")
  },
  {
    name: "admin-orders",
    path: "orders",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/orders.vue")
  },
  {
    name: "admin-products",
    path: "products",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/products.vue")
  },
  {
    name: "admin-categories",
    path: "categories",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/categories.vue")
  },
  {
    name: "admin-order-id",
    path: "order/:id()",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/order/[id].vue")
  },
  {
    name: "admin-variations",
    path: "variations",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/variations.vue")
  },
  {
    name: "admin-settings",
    path: "settings",
    component: () => createClientPage(() => import("/home/runner/work/Macabram/Macabram/pages/admin/settings.client.vue"))
  },
  {
    name: "admin-order-manual",
    path: "order/manual",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/order/manual/index.vue")
  },
  {
    name: "admin-order-manual-complete",
    path: "order/manual/complete",
    meta: complete05Qn7j4K8xjVkjQi3t5IyBb8mBfuXrW2Bt85cj3NgwcMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/admin/order/manual/complete.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/legal.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/logout.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/register.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard95wsXYzLww8KCVqqU0d6cOwSnhw7xpyHdhO5JQn93UYMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-home",
    path: "home",
    meta: homeiVcoqbryHRu0ymtwx7kTihWlHjiL5cnePWUFdpvBPWsMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard/home.vue")
  },
  {
    name: "dashboard-myorders",
    path: "myorders",
    meta: myordersLyMroVOboZziNNKX4bw_4592I2VcIK5gWAfqjuhYvtmDQMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard/myorders.vue")
  },
  {
    name: "dashboard-myaccount",
    path: "myaccount",
    meta: myaccountyCn4_45A8VC2rY_7lypN7wJ6CtNMOMepPBDNoCNgZ744kMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard/myaccount.vue")
  },
  {
    name: "dashboard-order-id",
    path: "order/:id()",
    meta: _91id_93p_LlDIWiSUeAZiNuusqBLG9_45ZLhj1kDIOIvxOCeGi3MMeta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/dashboard/order/[id].vue")
  }
]
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/maintenance.vue")
  },
  {
    name: "verify_email",
    path: "/verify_email",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/verify_email.vue")
  },
  {
    name: "account_deleted",
    path: "/account_deleted",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/account_deleted.vue")
  },
  {
    name: "shop-order",
    path: "/shop/order",
    meta: indexaTZcoucQaeRyEhWMeacIV1_KkA_HZWSxDYEz1TUpjK8Meta || {},
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/order/index.vue")
  },
  {
    name: "shop-order-success",
    path: "/shop/order/success",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/order/success.vue")
  },
  {
    name: "shop-order-canceled",
    path: "/shop/order/canceled",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/order/canceled.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password-token",
    path: "/forgot-password/:token()",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/forgot-password/[token].vue")
  },
  {
    name: "shop-product-id-name",
    path: "/shop/product/:id()-:name()",
    component: () => import("/home/runner/work/Macabram/Macabram/pages/shop/product/[id]-[name].vue")
  }
]